//
// Cookie Consent
//

#cookie-consent {
    .cc-message {
        font-family: $font-family-sans-serif;
        font-size: $small-font-size;
    }

    .cc-btn {
        border-radius: $border-radius;
    }

    .cc-banner {
        padding-left: calc($grid-gutter-width / 2);
        padding-right: calc($grid-gutter-width / 2);
        padding-top: .75rem;
        padding-bottom: .75rem;

        @include media-breakpoint-up(lg) {
            padding-left: calc($grid-gutter-width / 2) + $navbar-padding-x;
            padding-right: calc($grid-gutter-width / 2) + $navbar-padding-x;
        }
    }
}