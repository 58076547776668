/*

Theme: Square - Premium Bootstrap Theme
Product Page: https://themes.getbootstrap.com/product/square
Author: Webinning
Author URI: https://webinning.co.uk

---

Copyright 2020 Webinning

*/

// Fonts
@import "square/fonts";

// Functions
@import "../../node_modules/bootstrap/scss/functions";
@import "square/functions";

// Variables
@import "custom/variables";
@import "square/variables";
@import "../../node_modules/bootstrap/scss/variables";

// Maps
@import "../../node_modules/bootstrap/scss/maps";

// Mixins
@import "../../node_modules/bootstrap/scss/mixins";
@import "square/mixins";
@import "square/utilities";
@import "../../node_modules/bootstrap/scss/utilities";

// Bootstrap components
@import "square/bootstrap";

// Bootstrap Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Bootstrap utilities API
@import "../../node_modules/bootstrap/scss/utilities/api";

// Square components
@import "square/reboot";
@import "square/components";
@import "square/vendor"; 

// Custom styles
@import "custom/styles";