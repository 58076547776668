//
// Overlay
//

$opacity: (0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100);

.overlay {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@each $i in $opacity {
    .overlay-#{$i} {
        &::before {
            opacity: calc($i / 100)  !important;
        }
    }
}

@each $element, $value in $theme-colors {
    .overlay-#{$element} {
        &::before {
            background-color: $value !important;
        }
    }

    @each $i in $opacity {
        .overlay-#{$element}-gradient-top-#{$i} {
            &::before {
                background: linear-gradient(0deg, #{'rgba(#{red($value)},#{green($value)},#{blue($value)},0)'} 0, #{'rgba(#{red($value)},#{green($value)},#{blue($value)},1)'} #{$i+'%'}) !important;
            }
        }

        .overlay-#{$element}-gradient-bottom-#{$i} {
            &::before {
                background: linear-gradient(180deg, #{'rgba(#{red($value)},#{green($value)},#{blue($value)},0)'} 0, #{'rgba(#{red($value)},#{green($value)},#{blue($value)},1)'} #{$i+'%'}) !important;
            }
        }
    }
}